'use strict'
import slick from 'slick-carousel';

jQuery(function () {

    

    let hlholder = $('#home-highlights').find('.highlights');

    //Set Slick Nav Classes
    hlholder.on('init', function (event, slick, currentSlide, nextSlide) {
        if ($(slick.$slides[slick.$slides.length - 1]).hasClass('slick-active')) {
            $('.slick-nav-right').addClass('inactive');
        } else {
            $('.slick-nav-right').removeClass('inactive');
        }


        if ($(slick.$slides[0]).hasClass('slick-active')) {
            $('.slick-nav-left').addClass('inactive');
        } else {
            $('.slick-nav-left').removeClass('inactive');
        }
    });

    hlholder.on('afterChange', function (event, slick, currentSlide, nextSlide) {
        if ($(slick.$slides[slick.$slides.length - 1]).hasClass('slick-active')) {
            $('.slick-nav-right').addClass('inactive');
        } else {
            $('.slick-nav-right').removeClass('inactive');
        }


        if ($(slick.$slides[0]).hasClass('slick-active')) {
            $('.slick-nav-left').addClass('inactive');
        } else {
            $('.slick-nav-left').removeClass('inactive');
        }
    });

    //Init Slick
    hlholder.slick({
        slidesToShow: 1,
        variableWidth: true,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 10000,
        arrows: false,
        infinite: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    autoplaySpeed: 3000,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    autoplaySpeed: 2000,
                }
            }
        ]
    });


    // //Slick Nav
    $('.slick-nav-control').on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('slick-nav-left')) {
            hlholder.slick('slickPrev');
        }
        if ($(this).hasClass('slick-nav-right')) {
            hlholder.slick('slickNext');
        }

    })

});